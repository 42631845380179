import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";

export const teamMembers = [
  { id: "JJUhjdVvYNGjbbpdQWQ2", imageSrc: "assets/Team-imgs/Dr-maahin.jpeg" },
  { id: "GR7rNl7wPsXwr5d057bq", imageSrc: "assets/Team-imgs/Dr-Nayab.jpeg" },
  { id: "3mqelH4a59QFCukMLN2X", imageSrc: "assets/Team-imgs/dr-hira.jpeg" },
  { id: "0EUm4XElIvD2H5LFx1wd", imageSrc: "assets/Team-imgs/dr-fatima.jpeg" },
  { id: "4VIsH6DJo7c2phCbANDC", imageSrc: "assets/Team-imgs/dr-ayesha.jpeg" },
];

const Team = () => {
  const navigate = useNavigate();
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    const fetchFirestoreData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "doctors"));
        const firestoreData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const mergedData = firestoreData.map((firestoreMember, index) => ({
          ...firestoreMember,
          imageSrc: teamMembers[index]?.imageSrc || "assets/default-image.jpg",
        }));

        setTeamData(mergedData.reverse());
      } catch (error) {
        console.error("Error fetching Firestore data:", error);
      }
    };

    fetchFirestoreData();
  }, []);

  const handleScrollToVision = (route, id) => {
    navigate(route);
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <section className="py-16 bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 flex flex-col justify-center items-center">
        {/* Section Title */}
        <div data-aos="zoom-out" className="text-center mb-12">
          <h2 className="text-4xl font-semibold text-gray-900">
            Our <span className="text-yellow-500">Expert Team</span>
          </h2>
          <p className="mt-4 text-lg text-gray-600 font-mono">
            Our team of highly qualified mental health professionals is
            dedicated to providing exceptional care in psychology and
            psychiatry. Each member brings years of experience and a
            compassionate approach, ensuring every client receives personalized
            attention suited to their individual needs.
          </p>
        </div>

        {/* Team Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 md:grid-rows-2 lg:grid-cols-5 lg:grid-rows-1 gap-8 justify-center items-center">
          {teamData.map((member, index) => (
            <div
              data-aos={index % 2 === 0 ? "fade-down" : "fade-up"}
              key={index}
              className="bg-white h-full h-min-[380px] rounded-lg shadow-md text-center pb-5 duration-200 transform flex flex-col justify-between"
            >
              <div className="flex-grow">
                <div className="relative overflow-hidden pb-[100%] mb-6 rounded-lg bg-blue-100">
                  <img
                    className="absolute h-full w-full object-cover rounded-lg"
                    src={member.imageSrc || "assets/default-image.jpg"}
                    alt={member.name}
                  />
                </div>
                <div className="px-2">
                  <h3 className="text-xl sm:px-2 px-0 font-semibold text-gray-900">
                    {member.name}
                  </h3>
                  <p className="mt-2 text-[#FB8501]">{member.role}</p>
                </div>
              </div>

              <button
                onClick={() =>
                  handleScrollToVision(
                    `/doctor-profile/${member.id}`,
                    "dactarSaabbb"
                  )
                }
                className="px-4 py-2 m-4 mb-0 bg-[#FB8501] rounded-lg hover:bg-[#fb8601d7] hover:text-white hover:scale-105 transition-all ease-linear"
              >
                Read More
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
