import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import "./index.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { teamMembers } from "./components/TeamExperts";
import DoctorProfile from "./components/DoctorProfile.jsx";
import "./scss/Loader.scss";
// import { blogsData } from "./components/Blogs/blogData.js";
import BlogPage from "./components/Blogs/BlogPage.jsx";
import AllBlogs from "./components/Blogs/AllBlogs.jsx";
import Appointment from "./components//Appointments/Appointment.jsx";
import PhysicalAppointment from "./components/Appointments/PhysicalAppointment.jsx";
import OnlineAppointment from "./components/Appointments/OnlineAppointment.jsx";

const Home = React.lazy(() => import("./components/Home"));
const About = React.lazy(() => import("./components/About"));
const Contact = React.lazy(() => import("./components/Contact"));
const Services = React.lazy(() => import("./components/Services"));
const LazySubServices = React.lazy(() =>
  import("./components/SubServices/SubServices")
);

function App() {
  useEffect(() => {
    Aos.init({ once: true, duration: 1000 });
    Aos.refresh();
  }, []);

  return (
    <div className="main">
      <Router>
        <Suspense
          fallback={
            <div>
              <Navbar />
              <div className="flex my-48 mx-auto justify-center w-screen">
                <h1 className="text-5xl mr-5">Loading</h1>
                <MultiColorLoader />
              </div>
              <Footer />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutUs" element={<About />} />
            <Route path="/contactUs" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/service/:subServices" element={<LazySubServices />} />
            <Route path="*" element={<NotFound />} />
            <Route
              path="/doctor-profile/:doctorId"
              element={<DoctorProfileWrapper />}
            />
            <Route path="/blogs" element={<AllBlogs />} />
            <Route path="/blogs/:blogId" element={<BlogPage />} />
            <Route path="/book-appointment" element={<Appointment />} />
            <Route path="/book-appointment/physical" element={<PhysicalAppointment />} />
            <Route path="/book-appointment/online" element={<OnlineAppointment />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

const DoctorProfileWrapper = () => {
  const { doctorId } = useParams();
  // console.log("Extracted doctorId:", doctorId);

  const doctor = teamMembers?.find((member) => member.id === doctorId.trim());

  if (!doctor) {
    console.log("Doctor:", doctor);
    // console.log("teamMembers:", teamMembers);
    return (
      <>
        <Navbar />
        <div className="text-center my-20 px-96 min-h-56 w-full">
          <h1 className="text-4xl font-bold">Docdtor Not Found</h1>
          <p className="text-gray-600 mt-4">
            We couldn't find the doctor you're looking for.
          </p>
        </div>
        <Footer />
      </>
    );
  }

  return <DoctorProfile doctor={doctor} />;
};


const NotFound = () => {
  return (
    <>
      <Navbar />
      <NotFoundBody />
      <Footer />
    </>
  );
};

export const MultiColorLoader = () => {
  return (
    <div className="loader">
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
    </div>
  );
};

export const NotFoundBody = () => {
  return (
    <div className="min-h-[55vh] flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-9xl font-extrabold text-gray-800 tracking-widest animate-bounce">
        404
      </h1>

      <div className="bg-blue-500 px-2 text-sm rounded rotate-12 absolute animate-pulse">
        Page Not Found
      </div>

      <div className="mt-5">
        <Link
          to="/"
          className="relative inline-block text-sm font-medium text-blue-600 group"
        >
          <span className="absolute inset-0 w-full h-full transition-transform duration-300 scale-105 group-hover:scale-100 bg-blue-500 rounded-lg"></span>
          <span className="relative block px-8 py-3 bg-white border border-current rounded-lg group-hover:bg-blue-500 group-hover:text-white">
            Go Home
          </span>
        </Link>
      </div>
    </div>
  );
};

export default App;
