import { useState } from "react";
import { Link } from "react-router-dom";
import DropDown from "./DropDown";

const Navbar = () => {
  const servicesItems = [
    {
      label: "Psychological Services",
      path: "/service/psychological-services",
    },
    { label: "Psychiatry Services", path: "/service/psychiatric-services" },
    {
      label: "Speech-Language Therapy",
      path: "/service/speech-language-therapy",
    },
    { label: "Career Counseling", path: "/service/career-counseling" },
    { label: "Addiction / Rehab Counseling", path: "/service/rehab-services" },
    { label: "Electroconvulsive Therapy", path: "/service/electroconvulsive-therapy-ect"},
    { label: "Marital Counseling", path: "/service/marital-counseling" },
    {
      label: "Treatment of Psychiatric Illnesses",
      path: "/service/treatment-psychiatric-illnesses",
    },
  ];

  const blogsItems = [
    {
      label: "When to Seek Mental Health Help",
      path: "/blogs/mental-health-help",
    },
    {
      label: "Psychiatrist vs. Psychologist",
      path: "/blogs/psychiatrist-vs-psychologist",
    },
    {
      label: "Types of Depressive Disorders",
      path: "/blogs/depressive-disorders",
    },
    { label: "Types of Anxiety Disorders", path: "/blogs/anxiety-disorders" },
    { label: "Autism vs. ADHD", path: "/blogs/autism-vs-adhd" },
    {
      label: "Causes of Mental Health Issues",
      path: "/blogs/mental-health-factors",
    },
    { label: "Speech-Related Issues", path: "/blogs/speech-issues" },
    {
      label: "Benefits of Couples Counseling",
      path: "/blogs/couples-counseling",
    },
    {
      label: "Parent’s Guide to Child Mental Health",
      path: "/blogs/child-mental-health",
    },
    {
      label: "Understanding Bipolar Disorder",
      path: "/blogs/bipolar-disorder",
    },
    {
      label: "Medication & Therapy Combo",
      path: "/blogs/medication-and-therapy",
    },
    { label: "Sadness vs. Depression", path: "/blogs/sadness-vs-depression" },
    {
      label: "Signs of Psychiatric Emergencies",
      path: "/blogs/psychiatric-emergencies",
    },
  ];

  const blogsToShow = blogsItems.slice(0, 4).concat({
    label: "More Blogs",
    path: "/blogs",
    className: "more-blogs-link",
  });
  const [isNavbarOpen, setNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  return (
    <nav className="mirror sticky z-999 h-18 top-0 left-0 z-50 scroll-smooth bg-white border-gray-200 focus:scroll-auto shadow-lg w-full">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-2">
        <span className=" h-16 w-28 p-4 self-center text-2xl font-semibold whitespace-nowrap text-white flex items-center space-x-3 rtl:space-x-reverse">
          <Link to="/">
            <img
              src="/assets/logo.png"
              alt="logo"
              className="h-16 w-full min-w-44"
            />
          </Link>
        </span>
        <button
          onClick={toggleNavbar}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden focus:outline-none focus:ring-2"
          aria-controls="navbar-multi-level"
          aria-expanded={isNavbarOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            isNavbarOpen ? "block" : "hidden"
          } w-full md:flex justify-center items-center md:w-auto`}
          id="navbar-multi-level"
        >
          <ul className="flex flex-col items-center justify-center font-medium p-0 md:p-0 mt-4 border rounded-lg  md:space-x-1 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li className="hover:bg-[#219ebc] ease-linear hover:text-white rounded-lg duration-200">
              <Link to="/" className="block py-2 px-2" aria-current="page">
                Home
              </Link>
            </li>
            <li className="hover:bg-[#219ebc] ease-linear hover:text-white rounded-lg duration-200">
              <Link
                to="/services"
                className="block py-2 px-2 duration-300 rounded"
              >
                Services
              </Link>
            </li>
            <li className="flex items-center cursor-pointer sm:ml-0 hover:bg-[#219ebc] ease-linear rounded-lg duration-00">
              <DropDown items={servicesItems} title={"What We Offer"} />
            </li>
            <li className="hover:bg-[#219ebc] ease-linear hover:text-white rounded-lg duration-200">
              <Link
                to="/AboutUs"
                className="block py-2 px-2 duration-300 rounded"
              >
                About Us
              </Link>
            </li>
            <li className="flex items-center cursor-pointer sm:ml-0 hover:bg-[#219ebc] ease-linear rounded-lg duration-00">
              <DropDown items={blogsToShow} title={"Blogs"} />
            </li>
            <li className="hover:bg-[#219ebc] ease-linear hover:text-white rounded-lg duration-200">
              <Link
                to="/contactUs"
                className="block px-2 py-2 duration-300 rounded"
              >
                Contact Us
              </Link>
            </li>
            <li className="hover:bg-[#219ebc] bg-[#219ebc] text-white rounded-lg px-4 hover:bg-transparent border border-transparent hover:border-black hover:text-black hover:scale-100 ease-linear duration-200">
              <Link
                to="/book-appointment"
                className="block px-2 py-2 duration-300 rounded"
              >
                Appointment
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
