import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import AboutHeroSection from "./AboutSections/AboutHeroSection";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { MultiColorLoader } from "../App";

const DoctorProfile = () => {
  const navigate = useNavigate();
  const { doctorId } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleScrollToVision = (route, id) => {
    navigate(route);
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  useEffect(() => {
    const fetchDoctorData = async () => {
      try {
        const docRef = doc(db, "doctors", doctorId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDoctor({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.error("Doctor not found!");
        }
      } catch (error) {
        console.error("Error fetching doctor data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDoctorData();
  }, [doctorId]);

  if (loading) {
    return (
      <>
        <Navbar />
        <div className="flex items-center justify-center h-screen">
          <h1 className="text-5xl mr-5">Loading</h1>
          <MultiColorLoader />
        </div>
        <Footer />
      </>
    );
  }

  if (!doctor) {
    return (
      <div className="text-center my-20 px-96 min-h-56 w-full">
        <h1 className="text-4xl font-bold">Doctor Not Found</h1>
        <p className="text-gray-600 mt-4">
          We couldn't find the doctor you're looking for.
        </p>
      </div>
    );
  }

  const daysOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <div id="doctorProfile">
      <Navbar />
      <AboutHeroSection img={"team_bg"} h2={doctor.name} />
      <div className="max-w-4xl mx-auto my-12 shadow-2xl shadow-blue-500 py-12 px-6 bg-white rounded-lg">
        {/* Doctor's Image */}
        <div className="flex flex-col items-center mb-8 border-b-2 border-solid border-gray-400 pb-8">
          <img
            className="w-48 h-48 rounded-full object-cover mb-4"
            src={`${doctor.imgSrc || "assets/default-image.jpg"}`}
            alt={doctor.name}
          />
          <h2 className="text-3xl font-semibold text-gray-800">
            {doctor.name}
          </h2>
          <p className="text-md text-[#FB8501]">{doctor.role}</p>
        </div>

        {/* Doctor's Info */}
        <div className="mb-6 flex gap-4 items-center">
          <div className="bg-[#FB8501] rounded-full p-2 w-fit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-phone"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          </div>
          <p className="text-gray-700 text-lg">
            {doctor.phone || "+92 300 4220330"}
          </p>
        </div>

        <div className="mb-6 flex gap-4 items-center">
          <div className="bg-[#FB8501] rounded-full p-2 w-fit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-briefcase-business"
            >
              <path d="M12 12h.01" />
              <path d="M16 6V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2" />
              <path d="M22 13a18.15 18.15 0 0 1-20 0" />
              <rect width="20" height="14" x="2" y="6" rx="2" />
            </svg>
          </div>
          <p className="text-gray-700">{doctor.experience}</p>
        </div>

        <div className="mb-6 flex gap-4 items-center">
          <div className="bg-[#FB8501] rounded-full p-2 w-fit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-graduation-cap"
            >
              <path d="M21.42 10.922a1 1 0 0 0-.019-1.838L12.83 5.18a2 2 0 0 0-1.66 0L2.6 9.08a1 1 0 0 0 0 1.832l8.57 3.908a2 2 0 0 0 1.66 0z" />
              <path d="M22 10v6" />
              <path d="M6 12.5V16a6 3 0 0 0 12 0v-3.5" />
            </svg>
          </div>
          <p className="text-gray-700">{doctor.education}</p>
        </div>

        <div className="mb-6">
          <div className="bg-[#FB8501] px-2 py-6 sm:p-6 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-white text-center text-xl font-semibold mb-4">
              Working Hours
            </h2>
            <div className="space-y-3">
              {doctor.availability ? (
                Object.entries(doctor.availability).map(
                  ([day, hours], index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between text-white border-b border-white/20 pb-2"
                    >
                      <div className="flex items-center gap-4 sm:gap-[30px]">
                        <span className="font-medium w-20 text-sm sm:text-base">
                          {day}
                        </span>
                        <span className="font-light text-sm sm:text-base">
                          {typeof hours === "object"
                            ? `${hours.start} - ${hours.end}`
                            : hours || "Unavailable"}
                        </span>
                      </div>
                      <button
                        onClick={() => handleScrollToVision("/book-appointment", "appointment")}
                        className="text-white font-semibold text-sm sm:text-base hover:underline flex items-center"
                      >
                        BOOK NOW →
                      </button>
                    </div>
                  )
                )
              ) : (
                <p className="text-gray-200">
                  No availability information provided.
                </p>
              )}
            </div>
            <div className="mt-6 text-center">
              <button onClick={() => handleScrollToVision("/book-appointment", "appointment")} className="bg-white text-[#FB8501] py-2 px-4 rounded-lg font-semibold shadow-md hover:bg-gray-100 transition">
                BOOK YOUR CONSULTATION
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DoctorProfile;
